<template>
    <div class="common-element">
        <div class="mt-0">
            {{gender}}
            <div class="row">
                <div class="col-md-12">
                    <button @click="toastOpen('success')" class="btn btn-success me-1">Toast success</button>
                    <button @click="toastOpen('warning')" class="btn btn-warning me-1">Toast warning</button>
                    <button @click="toastOpen('error')" class="btn btn-danger me-1">Toast error</button>
                    <button @click="toastOpen('info')" class="btn btn-primary me-1">Toast info</button>
                    <button @click="$router.push({ name: 'admin' })" class="btn btn-primary me-1">Parent</button>
                    <button @click="$router.push({ name: 'test' })" class="btn btn-primary me-1">Child</button>
                    <button @click="$router.push({ name: 'list' })" class="btn btn-primary me-1">Child</button>
                    <br>
                    <button @click="show()" class="btn btn-primary me-1">Show</button>
                    <br>
                    <button @click="hide()" class="btn btn-primary me-1">Hide</button>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <label for="input" class="form-label text-secondary"><i class="fa-solid fa-envelope"></i>
                        Email</label>
                    <input type="email" class="form-control" id="input" placeholder="name@example.com" autocomplete="off">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <label for="datetime" class="form-label text-secondary"><i class="fa-solid fa-calendar"></i>
                        Datetime: {{ date }}</label>
                    <date-picker autocomplete="off" id="datetime" name="date" v-model="date" :config="options"></date-picker>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <drag-drop-file-upload ref="dragDropFileUpload" @handleInput="changeImage"></drag-drop-file-upload>
                </div>
            </div>
        </div>
        <slide>
            <button @click="hide" class="btn btn-primary">Hide</button>
        </slide>
        <button class="btn btn-success me-1 mt-2" @click="exportCsv">export csv</button>
        <button class="btn btn-success me-1 mt-2" @click="exportXlsx">export xlsx</button>
    </div>
</template>

<script>

import moment from "moment"
import DragDropFileUpload from "@/components/File"
import Slide from "@/components/Slide";
import ExportRepository from "@/services/api/ExportRepository";
import mixins from "@/mixins";

export default {
    name: "Login",
    data() {
        return {
            formLogin: {
                username: "",
                password: "",
            },
            date: null,
            File: {},
            gender: null
        };
    },
    components: {
        DragDropFileUpload,
        Slide
    },
    mixins:[
        mixins
    ],
    async created() {
        this.gender = await this.getCodeDefine("00000100")
        console.log(this.gender);
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                locale: moment.locale("ja"),
            }
        }
    },
    methods: {
        toastOpen(type) {
            this.$toast.open({
                message: type,
                type: type
            })
        },
        uploadFile(e) {
            this.File = e.target.files;
        },
        dragFile(e) {
            this.date = 123;
            this.File = e.dataTransfer.files;
        },
        changeImage({file, filePreview}) {
            console.log({file, filePreview});
        },
        show() {
            this.$store.commit("showSlide");
        },
        hide() {
            this.$store.commit("hideSlide");
        },
        exportCsv() {
            ExportRepository.getCsv().then((res) => {
                const blob = new Blob([res.data], { type: "application/csv" })
                const link = document.createElement("a")
                link.href = URL.createObjectURL(blob)
                link.download = res.headers["pragma"]
                link.click()
                URL.revokeObjectURL(link.href)
            })
        },
        exportXlsx() {
            ExportRepository.getXlsx().then((res) => {
                const blob = new Blob([res.data], { type: res.headers["content-type"] })
                const link = document.createElement("a")
                link.href = URL.createObjectURL(blob)
                link.download = res.headers["pragma"];
                link.click()
                URL.revokeObjectURL(link.href)
            })
        }
    }
};
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
</style>
