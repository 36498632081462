<template>
    <div>
        <div class="drag-drop-upload" @dragover="handleDragOver" @dragleave="handleDragOver" @drop="handleFileSelect"
              :class="{'dragging' : dragging}">
            <label for="file-input" class="file-input" id="file-input-container" @click="clickInput">
                <slot name="upload-container" v-if="!dragging && results?.name == null ">
                    ファイル選択またはここにドラッグ
                </slot>
                <slot name="dragging-container" v-if="dragging">
                    ファイル選択またはここにドラッグ...
                </slot>
                <slot name="dragging-container" v-if="results">
                    {{results.name}}
                </slot>
                <input id="file-input" type="file" :accept="supportedFile" @change="handleFileInput" ref="fileInput">
            </label>
            <button class="btn btn-select" @click="clickInput">参照</button>
            <button class="btn btn-delete" @click="clearResults">取消</button>
        </div>
        <div v-if="results !== null && supportedFile === 'image/*'" class="file-results">
            <div class="image-preview" :style="`background-image: url('${results.filePreview}')`">
<!--                <a @click="deleteResults" href="javascript:;" class="delete">-->
<!--                    Delete-->
<!--                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name    : "DragDropFileUpload",
    props   : {
        supportedFile : {
            type    : String,
            default : "image/*"
        },
    },
    data() {
        return {
            dragging : false,
            results  : null
        };
    },
    methods : {
        handleFileInput(event) {
            this.processFileInput(event.target.files);
            event.target.value = "";
        },
        handleDragOver(event) {
            event.stopPropagation();
            event.preventDefault();
            this.dragging = event.type === "dragover";
            event.dataTransfer.dropEffect = "copy";
        },
        handleFileSelect(event) {
            event.stopPropagation();
            event.preventDefault();
            this.dragging = false;
            this.processFileInput(event.dataTransfer.files);
        },
        processFileInput(files) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let reader = new FileReader();
                reader.onloadend = function () {
                    if (this.results === null)
                        this.results = [];
                    const results = {
                        filePreview: reader.result,
                        file: file,
                        name: file.name
                    };
                    this.results = results;
                    this.$emit("handleInput", results);
                }.bind(this);
                reader.readAsDataURL(file);
            }
        },
        clearResults() {
            this.results = null;
            this.$emit("handleInput", []);
        },
        clickInput() {
            this.$refs.fileInput.click();
        }
        // deleteResults() {
        //     console.log("delete file");
        //     this.results = null;
        //     this.$emit("handleInput", this.results);
        // }
    }
};
</script>

<style lang="scss" scoped>
    .drag-drop-upload {
        display: flex;
        align-items: center;
        border-radius: 6px;
        width: fit-content;
        &.dragging {
            background-color: #e2e8f0;
            border-color: #667eea;
            width: fit-content;
        }
        > .file-input {
            height: 100%;
            pointer-events: none;
            margin-bottom: 0;
            > input {
                display: none;
            }
        }
        label {
            line-height: 36px;
            padding: 0 1em;
            width: 100%;
            color: #ced4da;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 260px;
            min-width: 260px;
            border: 1px solid #ced4da;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        .btn-select,
        .btn-delete {
            border-radius: 0;
            padding-left: 1.5em;
            padding-right: 1.5em;
            min-width: 85px;
            /*border: 1px solid #e2e8f0;*/
        }
        .btn-delete {
            background: rgb(255 54 54);
            color: rgba(255, 255, 255, 0.7);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            &:hover {
                color: rgba(255, 255, 255, 1)
            }
        }
        .btn-select {
            background: rgb(21, 61, 119);
            color: rgba(255, 255, 255, 0.7);
            &:hover {
                color: rgba(255, 255, 255, 1)
            }
        }
    }
    .file-results {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        .image-preview {
            max-width: calc(100%/4);
            border-radius: 0.375rem;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
            height: 200px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            overflow: hidden;
            >.delete {
                position: absolute;
                background: #bd2130;
                border-top-left-radius: 0.375rem;
                color: white;
                padding: 6px 10px;
                bottom: 0;
                right: 0;
                &:hover {
                    background: #c82333;
                }
            }
        }
    }
</style>